<template>
  <div class="login-container">
    <qrcode :text="codeurl" :size="300" ref="qrcode"></qrcode>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codeurl:
        "http://www.siruinfo.com/qrcode-boot/api/shangy/WxRSendUrl?backurl=http://www.siruinfo.com/autho2?macno=20221108_22MD_2",
    };
  },
  created() {
      //这个是小睿的专属连接
      let backurl = document.URL;
      if (backurl.endsWith("success")) {
         alert("我不跳转了，开始洗车")
      }
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
